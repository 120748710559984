// 待核实
<template>
  <div>
    <business-cpn :chooseType="5"></business-cpn>
  </div>
</template>

<script>
import businessCpn from '../../components/business/businessCpn.vue'
export default {
  name: "",
  components: {
    businessCpn
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang=scss>
</style>